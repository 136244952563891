import { Component } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

class KunjungModal extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        {this.props.title ? (
          <ModalHeader className="border-0" toggle={this.props.toggle}>
            {this.props.title}
          </ModalHeader>
        ) : null}
        <ModalBody>{this.props.children}</ModalBody>
        {this.props.componentFooter ? (
          <ModalFooter className="border-0">
            {this.props.componentFooter}
          </ModalFooter>
        ) : null}
      </Modal>
    )
  }
}

export default KunjungModal
