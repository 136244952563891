import { admin } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  data: [],
  detail: {},
}

function adminRecucer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(admin.GET_ADMIN):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(admin.GET_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(admin.GET_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        data: action.payload ? action.payload.data.result : [...state.data],
      }
    case pendingAction(admin.ADD_ADMIN):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(admin.ADD_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload
          ? action.payload.isAxiosError
            ? action.payload.message
            : action.payload.data.message
          : 'Unknown Error!',
      }
    case fullfilledAction(admin.ADD_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(admin.EDIT_ADMIN):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(admin.EDIT_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(admin.EDIT_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(admin.DELETE_ADMIN):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(admin.DELETE_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(admin.DELETE_ADMIN):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    default:
      return state
  }
}

export default adminRecucer
