import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class GetNotif extends Action {
  constructor() {
    super()
    this.type = person.PERSON_NOTIF
    this.url = '/person/notif'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetNotif).get(this.url, this.config),
    }
  }
}

export default GetNotif
