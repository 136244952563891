import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class EditPerson extends Action {
  constructor() {
    super()
    this.type = person.EDIT_PERSON
    this.url = '/person'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(EditPerson).put(this.uri, this.data, this.config),
    }
  }
}

export default EditPerson
