import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'
import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'
import KunjungModal from '../../components/Modal/Modal'

import GetDevice from '../../redux/actions/device/GetDevice'
import { connect } from 'react-redux'
import AddDevice from '../../redux/actions/device/AddDevice'
import SyncDevice from '../../redux/actions/device/SyncDevice'
import DeleteBody from '../../components/Modal/Delete'
import EditDevice from '../../redux/actions/device/EditDevice'
import DeleteDevice from '../../redux/actions/device/DeleteDevice'
import KunjungToast from '../../components/Modal/Toast'
import SyncModal from '../../components/Modal/SyncModal'

class Device extends Component {
  constructor(props) {
    super(props)
    this.actionGetDevice = new GetDevice()
    this.addDevice = new AddDevice()
    this.syncDevice = new SyncDevice()
    this.editDevice = new EditDevice()
    this.deleteDevice = new DeleteDevice()
    this.state = {
      isModalOpen: false,
      modalType: 1,
      searchValue: '',
      status: true,
      host: '',
      username: '',
      place: '',
      password: '',
      selectedId: '',
    }

    this.actionGetDevice.params = {
      status: this.state.status,
    }
  }

  get data() {
    return {
      host: this.state.host,
      username: this.state.username,
      place: this.state.place,
      password: this.state.password,
    }
  }

  modalToggle() {
    this.setState((prevState) => {
      return {
        isModalOpen: !prevState.isModalOpen,
        host: '',
        username: '',
        place: '',
        password: '',
      }
    })
  }

  getModalTitle() {
    switch (this.state.modalType) {
      case 1:
        return 'Add Device'
      case 2:
        return 'Edit Device'
      case 3:
        return 'Delete Device'
      case 4:
        return 'Sync Device'
      default:
        return 'Add Device'
    }
  }

  getModalType() {
    switch (this.state.modalType) {
      case 1:
        return this.getModalAdd()
      case 2:
        return this.getModalAdd()
      case 3:
        return this.getModalDelete()
      case 4:
        return <SyncModal />
      default:
        return this.getModalDelete()
    }
  }

  getModalDelete() {
    return <DeleteBody title="Apakah Anda Yakin ingin menghapus device ini ?" />
  }

  onClickSync(id) {
    this.setState(
      {
        selectedId: id,
        modalType: 4,
      },
      () => {
        this.modalToggle()
      }
    )
  }

  onSubmitSync() {
    this.syncDevice.id = this.state.selectedId

    this.props
      .dispatch(this.syncDevice.action())
      .then(() => {
        this.modalToggle()
        this._getDevice()
        KunjungToast({ title: 'Sync Device Success!' })
      })
      .catch((error) => {
        if (error.response) {
          KunjungToast({ title: error.response.data.message, icon: 'error' })
          return
        }
        KunjungToast({ title: error.message, icon: 'error' })
      })
  }

  getModalAdd() {
    return (
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault()

            this.getSubmitForm()
          }}
          id="kunjung"
        >
          <FormGroup>
            <Label for="host">Ip Address :</Label>
            <KunjungInput
              type="text"
              id="host"
              onChange={(e) => this.setState({ host: e.target.value })}
              value={this.state.host}
            />
          </FormGroup>
          <FormGroup>
            <Label for="place">Place :</Label>
            <KunjungInput
              type="text"
              id="place"
              onChange={(e) => this.setState({ place: e.target.value })}
              value={this.state.place}
            />
          </FormGroup>
          <FormGroup>
            <Label for="username">Username :</Label>
            <KunjungInput
              type="text"
              id="username"
              onChange={(e) => this.setState({ username: e.target.value })}
              value={this.state.username}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password :</Label>
            <KunjungInput
              type="password"
              id="password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </FormGroup>
        </Form>
      </Container>
    )
  }

  getSubmitForm() {
    switch (this.state.modalType) {
      case 1:
        return this.submitAdd()
      case 2:
        return this.submitEdit()
      case 3:
        return this.submitDelete()
      case 4:
        return this.onSubmitSync()
      default:
        return this.submitAdd()
    }
  }

  submitDelete() {
    this.deleteDevice.id = this.state.selectedId
    this.props.dispatch(this.deleteDevice.action()).then(() => {
      this._getDevice()
      this.modalToggle()
      KunjungToast({ title: 'Device Deleted!' })
    })
  }

  submitEdit() {
    this.editDevice.id = this.state.selectedId
    this.editDevice.data = this.data
    this.props.dispatch(this.editDevice.action()).then(() => {
      this._getDevice()
      this.modalToggle()
      KunjungToast({ title: 'Edit Device Success!' })
    })
  }

  submitAdd() {
    this.addDevice.data = this.data
    this.props
      .dispatch(this.addDevice.action())
      .then(() => {
        this._getDevice()
        this.modalToggle()
        KunjungToast({ title: 'Add Device Success!' })
      })
      .catch((error) => {
        if (error.response) {
          KunjungToast({ title: error.response.data.message, icon: 'error' })
          return
        }
        KunjungToast({ title: error.message, icon: 'error' })
      })
  }

  onModalDeleteClick(id) {
    this.setState(
      {
        modalType: 3,
        selectedId: id,
      },
      () => {
        this.modalToggle()
      }
    )
  }

  onModalEditClick(device) {
    this.setState(
      {
        modalType: 2,
      },
      () => {
        this.modalToggle()
        this.setState({
          place: device.place,
          selectedId: device.id,
          host: device.host,
          username: device.username,
        })
      }
    )
  }

  onClickAdd() {
    this.setState({ modalType: 1 }, () => {
      this.modalToggle()
    })
  }

  _getParams() {
    return {
      search: this.state.searchValue,
      status: true,
    }
  }
  _getDevice() {
    this.props.dispatch(this.actionGetDevice.action())
  }

  _handleSearchKeyDown(e) {
    if (e.key === 'Enter') {
      this.actionGetDevice.params = this._getParams()
      this._getDevice()
    }
  }

  componentDidMount() {
    this._getDevice()
  }
  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex">
            <h3 className="mr-auto mb-0">Device</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <KunjungInput
                className="kunjung-search"
                bsSize="sm"
                placeholder="Search here..."
                onKeyDown={(e) => this._handleSearchKeyDown(e)}
                onChange={(e) => this.setState({ searchValue: e.target.value })}
              />
            </form>
            <KunjungButton
              className="ml-2"
              size="sm"
              onClick={this.onClickAdd.bind(this)}
            >
              Add Device
            </KunjungButton>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Ip Address</th>
                <th scope="col">Place</th>
                <th scope="col">Fingerprint Data</th>
                <th scope="col">User Data</th>
                <th scope="col">Status</th>
                <th scope="col">Is Updated</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.device.isLoading ? (
                <tr>
                  <td colSpan="7">
                    <Spinner type="grow" color="primary" />
                  </td>
                </tr>
              ) : (
                this.props.device.data.map((device, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{device.host}</td>
                    <td>{device.place}</td>
                    <th scope="row">{device.fingerprint_total || 0}</th>
                    <th scope="row">{device.person_total || 0}</th>
                    <td>
                      <Badge
                        color={
                          device.status && device.status !== 'online'
                            ? 'danger'
                            : !device.isAuth
                            ? 'warning'
                            : 'success'
                        }
                        className="badge-lg text-capitalize"
                      >
                        {device.status && device.status !== 'online'
                          ? 'Offline'
                          : !device.isAuth
                          ? 'warning'
                          : 'Online'}
                      </Badge>
                    </td>
                    <th scope="row">{device.status_sync || 'unknown'}</th>
                    <td>
                      <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        color=""
                        onClick={() => this.onModalEditClick(device)}
                      >
                        <i className="far fa-edit kunjung-text-primary"></i>
                      </Button>
                      <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        color=""
                        onClick={() => {
                          this.onClickSync(device.id)
                        }}
                      >
                        <i className="fas fa-sync kunjung-text-primary"></i>
                      </Button>
                      <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        color=""
                        onClick={() => this.onModalDeleteClick(device.id)}
                      >
                        <i className="far fa-trash-alt kunjung-text-primary"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <CardFooter className="py-4"></CardFooter>
        </Card>
        <KunjungModal
          className="modal-dialog-centered"
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle.bind(this)}
          title={<h2>{this.getModalTitle()}</h2>}
          componentFooter={
            <Container>
              <Row>
                <Col>
                  <KunjungButton
                    outline
                    onClick={this.modalToggle.bind(this)}
                    block
                  >
                    Batal
                  </KunjungButton>
                </Col>
                <Col>
                  <KunjungButton
                    type="submit"
                    block
                    onClick={() => {
                      if (
                        this.state.modalType != 1 &&
                        this.state.modalType != 2
                      ) {
                        this.getSubmitForm()
                      }
                    }}
                    form="kunjung"
                  >
                    {this.props.device.isLoading ? (
                      <i class="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      'Submit'
                    )}
                  </KunjungButton>
                </Col>
              </Row>
            </Container>
          }
        >
          {this.getModalType()}
        </KunjungModal>
      </KunjungAdminLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps)(Device)
