import Action from '../Action'
import { admin } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'

class GetAdmin extends Action {
  constructor() {
    super()
    this.type = admin.GET_ADMIN
    this.url = '/admin'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetAdmin).get(this.url, this.config),
    }
  }
}

export default GetAdmin
