import { Component } from 'react'

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'

import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'
import KunjungAuthLayout from '../layout/KunjungAuthLayout'

import { kunjungAuthLayoutStyle } from '../layout/styles'

import { login } from '../../redux/actions/auth'
import { connect } from 'react-redux'

import Select from 'react-select'
import GetPerson from '../../redux/actions/person/GetPerson'
import AddFace from '../../redux/actions/person/AddFace'
import { getStatusPerson, imgSync } from '../../helper'
import imageCompression from 'browser-image-compression'
import KunjungToast from '../../components/Modal/Toast'

class Registration extends Component {
  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
      step: 1,
      nip: '',
      fullname: '',
      absentId: '',
      status: 0,
      value: null,
      faceImage: null,
      cameraVisible: false,
      isLoading: false,
    }

    this.getPerson = new GetPerson()
    this.addFace = new AddFace()
  }

  getOption() {
    const persons = this.props.persons.map((person) => {
      return {
        value: person.id,
        label: `${person.name} - ${person.nip}`,
        data: person,
      }
    })
    return persons
  }

  onSubmitLogin(e) {
    console.log(this.props)
    e.preventDefault()
    const self = this

    const data = {
      username: self.state.username,
      password: self.state.password,
    }
    console.log(this.props)
    this.props.dispatch(login(data))
  }

  async handleTakePhotoAnimationDone(dataKamera) {
    const image = await this.compressImg(dataKamera)
    this.setState({ faceImage: image, cameraVisible: false })

    this.setState({ cameraVisible: false })
  }

  _getPerson() {
    this.props.dispatch(this.getPerson.action())
  }
  onChangeSelect(e) {
    if (e.length >= 3) {
      this.getPerson.params = {
        search: e,
      }

      this._getPerson()
    }
  }

  async compressImg(img) {
    const imgFile = await imageCompression.getFilefromDataUrl(
      img,
      'kunjung.jpg'
    )

    const newImage = await imageCompression(imgFile, {
      maxSizeMB: 0.18,
      maxWidthOrHeight: 1000,
    })

    const newBase64 = await imgSync(newImage)

    return newBase64
  }

  get data() {
    return {
      person_id: this.state.value.value,
      image: this.state.faceImage,
      isUpdate: this.state.status !== 1,
    }
  }

  onChangePerson(e) {
    this.setState({
      name: e.data.name,
      absentId: e.data.employeeNo,
      status: e.data.status,
      value: e,
    })
  }

  step1() {
    return (
      <>
        <div
          className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.name}`}
        >
          <label htmlhtmlFor="fnip"> NIP / Nama</label>
          <Select
            styles={{
              option: (provided) => ({
                ...provided,
                backgroundColor: '#73008A',
              }),
              placeholder: () => ({
                display: 'none',
              }),
              control: () => ({
                border: '2px solid #73008A',
                borderRadius: '4px',
                height: '100%',
                width: '100%',
              }),
              valueContainer: () => ({
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'hidden',
                height: 'calc(1.5em + 1.25rem + 2px)',
              }),
              indicatorsContainer: () => ({
                display: 'none',
              }),
              input: () => ({
                display: 'block',
                height: 'calc(1.5em + 1.25rem + 2px)',
                width: '100%',
              }),
              singleValue: () => ({
                display: 'block',
                height: 'calc(1.5em + 1.25rem + 2px)',
                width: '100%',
                padding: '10px',
              }),
              menu: () => ({
                color: 'white',
              }),
            }}
            isDisabled={this.state.step === 3}
            onInputChange={(e) => this.onChangeSelect(e)}
            options={this.getOption()}
            onChange={(e) => this.onChangePerson(e)}
            value={this.state.value}
          />
        </div>

        <div
          className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.name} mt-4`}
        >
          <label htmlhtmlFor="fname"> Full Name :</label>
          <KunjungInput
            disabled
            type="text"
            id="fname"
            name="fname"
            value={this.state.name}
          />
        </div>

        <div
          className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.idNumber}`}
        >
          <label htmlhtmlFor="fabsen"> Absent ID :</label>
          <KunjungInput
            disabled
            type="text"
            id="fabsen"
            name="fabsen"
            value={this.state.absentId}
          />
        </div>

        <div
          className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.idNumber}`}
        >
          <label htmlhtmlFor="fphone"> Status </label>
          <KunjungInput
            disabled
            type="text"
            id="fstatus"
            name="fstatus"
            value={getStatusPerson(this.state.status)}
          />
        </div>
      </>
    )
  }

  camera() {
    return (
      <>
        <div className={kunjungAuthLayoutStyle.cameraContainer}>
          {this.state.cameraVisible ? (
            <div className={kunjungAuthLayoutStyle.camera}>
              <Camera
                idealFacingMode={FACING_MODES.USER}
                idealResolution={{ width: 1000, height: 1000 }}
                imageType={IMAGE_TYPES.JPG}
                isMaxResolution={true}
                isImageMirror={false}
                onTakePhotoAnimationDone={(dataKamera) => {
                  this.handleTakePhotoAnimationDone(dataKamera)
                }}
                // sizeFactor={0.18}
                onCameraError={(error) => {
                  document.getElementById('secondaryCamera').click()
                }}
                isSilentMode={false}
                isDisplayStartCameraError={true}
                isFullscreen={false}
              />

              <input
                type="file"
                style={{
                  display: 'none',
                }}
                id="secondaryCamera"
                accept="image/jpeg"
                capture="camera"
                onChange={async (e) => {
                  if (!e.target.files[0]) {
                    return
                  }
                  const base64 = await imgSync(e.target.files[0])
                  const image = await this.compressImg(base64)
                  this.setState({
                    cameraVisible: false,
                    faceImage: image,
                  })
                }}
              />
            </div>
          ) : (
            <>
              {this.state.faceImage ? (
                <div
                  className={`mx-auto ${kunjungAuthLayoutStyle.previewImage}`}
                >
                  <img
                    src={this.state.faceImage}
                    alt="Tipe file tidak di kenali, mohon ubah ke gambar"
                  />
                </div>
              ) : null}

              {this.state.step !== 3 ? (
                <KunjungButton
                  className={kunjungAuthLayoutStyle.primaryBtn}
                  onClick={() => {
                    this.setState({
                      cameraVisible: true,
                    })
                  }}
                >
                  <i className="fas fa-camera"></i>
                </KunjungButton>
              ) : null}
            </>
          )}
        </div>
      </>
    )
  }

  step2() {
    return (
      <>
        <section className={kunjungAuthLayoutStyle.copy}>
          <p>
            <strong>Take photo for Attendance Face Data</strong>
          </p>
        </section>
        {this.camera()}
      </>
    )
  }

  step3() {
    return (
      <>
        {this.camera()}
        {this.step1()}
      </>
    )
  }

  getDisabledNext() {
    if (this.state.step === 1 && this.state.value) {
      return false
    }

    if (this.state.step === 2 && this.state.faceImage) {
      return false
    }

    return true
  }

  onClickSubmit() {
    if (this.state.isLoading) {
      return
    }
    this.addFace.data = this.data
    this.setState({
      isLoading: true,
    })
    this.props
      .dispatch(this.addFace.action())
      .then(() => {
        this.setState({ step: 4, isLoading: false })
      })
      .catch((error) => {
        KunjungToast({ icon: 'error', title: error.message })
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <KunjungAuthLayout className="mt-5">
        <section className={kunjungAuthLayoutStyle.copy}>
          <h2 className={kunjungAuthLayoutStyle.h2Class}>Registration</h2>
        </section>
        <div className={`mb-3 ${kunjungAuthLayoutStyle.stepper}`}>
          <div
            className={`${kunjungAuthLayoutStyle.round} ${
              this.state.step > 0 ? kunjungAuthLayoutStyle.active : null
            } `}
          >
            <div className={kunjungAuthLayoutStyle.roundDash}>
              <p>
                <strong>1</strong>
              </p>
            </div>
          </div>

          <div
            className={`${kunjungAuthLayoutStyle.round} ${
              this.state.step > 1 ? kunjungAuthLayoutStyle.active : null
            }`}
          >
            <div className={kunjungAuthLayoutStyle.roundDash}>
              <p>
                <strong>2</strong>
              </p>
            </div>
          </div>

          <div
            className={`${kunjungAuthLayoutStyle.round} ${
              this.state.step > 2 ? kunjungAuthLayoutStyle.active : null
            }`}
          >
            <div className={kunjungAuthLayoutStyle.roundDash}>
              <p>
                <strong>3</strong>
              </p>
            </div>
          </div>
        </div>
        <form
          onSubmitCapture={this.onClickSubmit}
          method="POST"
          className={`mb-3 ${kunjungAuthLayoutStyle.formClass}`}
        >
          {this.state.step === 1 ? this.step1() : null}
          {this.state.step === 2 ? this.step2() : null}

          {this.state.step === 3 ? this.step3() : null}

          {this.state.step < 3 ? (
            <KunjungButton
              disabled={this.getDisabledNext()}
              className={`${kunjungAuthLayoutStyle.signupBtn} mb-3`}
              onClick={() => this.setState({ step: this.state.step + 1 })}
            >
              Next
            </KunjungButton>
          ) : null}

          {this.state.step === 3 ? (
            <KunjungButton
              onClick={() => this.onClickSubmit()}
              className={`mb-3 ${kunjungAuthLayoutStyle.signupBtn}`}
            >
              {this.state.isLoading ? (
                <i class="fas fa-circle-notch fa-spin"></i>
              ) : (
                'Submit'
              )}
            </KunjungButton>
          ) : null}
          {this.state.step > 1 && this.state.step !== 4 ? (
            <KunjungButton
              outline
              onClick={() => this.setState({ step: this.state.step - 1 })}
              className={kunjungAuthLayoutStyle.signupBtn}
            >
              Previous
            </KunjungButton>
          ) : null}

          {this.state.step === 2 ? (
            <label
              className={`${kunjungAuthLayoutStyle.m1} kunjung-text-primary`}
              ref={this.messagesEnd}
            >
              Don't worry, we will protect your data
            </label>
          ) : null}

          {this.state.step === 4 ? (
            <>
              <img
                src={require('../../assets/img/vector/success.png').default}
                alt=""
              />
              <label
                className={`${kunjungAuthLayoutStyle.m1} kunjung-text-primary`}
                ref={this.messagesEnd}
              >
                Pendaftaran berhasil, silahkan konfirmasi dengan bagian SDM
                untuk persetujuan data dan pendaftaran fingerprint
              </label>
              <KunjungButton
                onClick={() => window.location.reload()}
                className={`${kunjungAuthLayoutStyle.signupBtn} mb-5 mt-3`}
              >
                Oke
              </KunjungButton>
            </>
          ) : null}
        </form>
      </KunjungAuthLayout>
    )
  }
}

function mapStateTopProps(state) {
  return {
    persons: state.person.data,
  }
}
export default connect(mapStateTopProps)(Registration)
