import Factory from './Factory'
import KunjungRoute from '../routes/KunjungRoute'
import PersahabatanRoute from '../routes/PersahabatanRoute'
import AntvRoute from '../routes/AntvRoute'

class RouteFactory extends Factory {
  create(param) {
    if (param === 'persahabatan') {
      return new PersahabatanRoute()
    }

    if (param === 'antv') {
      return new AntvRoute()
    }

    return new KunjungRoute()
  }
}

export default RouteFactory
