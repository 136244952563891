import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { admin } from '../../actionTypes'

class EditAdmin extends Action {
  constructor() {
    super()
    this.type = admin.EDIT_ADMIN
    this.url = '/admin'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(EditAdmin).put(this.uri, this.data, this.config),
    }
  }
}

export default EditAdmin
