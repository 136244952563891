import { setting } from '../../../actionTypes'
import apiAdapter from '../../../helpers/apiAdapter'
import Action from '../../Action'

class GetSetting extends Action {
  constructor() {
    super()
    this.type = setting.GET_SETTING
    this.url = '/setting'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetSetting).get(this.url, this.config),
    }
  }
}

export default GetSetting
