import Factory from './Factory'
import Header from '../components/Headers/Header'

class HeaderFactory extends Factory {
  create(param) {
    // if (param === 'persahabatan') {
    //   return new HeaderPersahabatan
    // }

    return Header
  }
}

export default HeaderFactory
