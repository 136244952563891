import { attendance } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  data: [],
  detail: {},
  page: { total: 0, current: 1 },
}

function attendanceRecucer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(attendance.GET_ATTENDANCE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(attendance.GET_ATTENDANCE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(attendance.GET_ATTENDANCE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        data: action.payload.data.result,
        page: action.payload.data.page,
      }
    default:
      return state
  }
}

export default attendanceRecucer
