import React, { Component } from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'

class KunjungInput extends Component {
  render() {
    return (
      <Input
        {...this.props}
        className={`kunjung-form-input${
          this.props.className ? ' ' + this.props.className : ''
        }`}
        type={this.props.type}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    )
  }
}

const propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
}

const defaultProps = {
  outline: false,
}

KunjungInput.propTypes = propTypes
KunjungInput.defaultProps = defaultProps

export default KunjungInput
