import React from 'react'
import { Container } from 'reactstrap'

function DeleteBody(props) {
  return (
    <Container>
      <div className="d-flex justify-content-center mb-3">
        <img src={require('../../assets/img/vector/junk.png').default} alt="" />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <h3>{props.title || 'Apakah Anda Yakin ?'}</h3>
      </div>
    </Container>
  )
}

export default DeleteBody
