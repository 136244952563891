import Action from '../Action'
import { attendance } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'

class GetAttendace extends Action {
  constructor() {
    super()
    this.type = attendance.GET_ATTENDANCE
    this.url = '/attendance'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetAttendace).get(this.url, this.config),
    }
  }
}

export default GetAttendace
