import React from 'react'
import { Container } from 'reactstrap'

function LoadingModal(props) {
  return (
    <Container>
      <div className="d-flex justify-content-center mb-3">
        <img
          src={require('../../assets/img/vector/4966418 1.png').default}
          alt=""
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <img
          src={require('../../assets/img/vector/Spinner-1s-200px.svg').default}
          alt=""
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <h3>Please wait a few minutes...</h3>
      </div>
    </Container>
  )
}

export default LoadingModal
