import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'
import KunjungButton from '../../components/Button/Button'

import KunjungModal from '../../components/Modal/Modal'

import { connect } from 'react-redux'
import DetailPerson from '../../redux/actions/person/DetailPerson'

import PersonApprove from '../../redux/actions/person/PersonApprove'
import PersonReject from '../../redux/actions/person/PersonReject'
import ClearFingerprint from '../../redux/actions/person/ClearFingerprint'
import CaptureFingerprint from '../../redux/actions/person/CaptureFingerprint'
import Question from '../../components/Modal/Question'
import KunjungToast from '../../components/Modal/Toast'
import LoadingModal from '../../components/Modal/LoadingModal'

class Approval extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      modalType: 3,
      image: '',
    }
    this.getPerson = new DetailPerson()
    this.personApprove = new PersonApprove()
    this.personReject = new PersonReject()
    this.clearFingerprint = new ClearFingerprint()
    this.captureFingerprint = new CaptureFingerprint()
  }

  modalToggle() {
    this.setState((prevState) => {
      return {
        isModalOpen: !prevState.isModalOpen,
      }
    })
  }

  get data() {
    return {
      person_id: this.props.match.params.id,
    }
  }

  onClickReject() {
    this.personReject.data = this.data

    this.props.dispatch(this.personReject.action()).then(() => {
      this.modalToggle()
      KunjungToast({ title: 'User Rejected!' })
    })
  }

  onClickApprove() {
    this.personApprove.data = this.data

    this.props.dispatch(this.personApprove.action()).then((res) => {
      console.log(res.value.data)
      this.modalToggle()
      KunjungToast({ title: 'User Approved!' })

      if (
        res.value.data &&
        res.value.data.duplicateFp &&
        res.value.data.duplicateFp.length > 0
      ) {
        this.setState(
          {
            modalType: 5,
          },
          () => this.modalToggle()
        )
      }
    })
  }

  onClickClearFingerprint() {
    this.clearFingerprint.id = this.props.match.params.id

    this.props.dispatch(this.clearFingerprint.action()).then(() => {
      this._getPerson()
      this.modalToggle()
      KunjungToast({ title: 'Clear Fingerprint Success!' })
    })
  }

  onClickCaptureFingerprint() {
    this.captureFingerprint.data = {
      finger_no: this.props.person.finger_total + 1,
      person_id: this.props.match.params.id,
    }

    this.props
      .dispatch(this.captureFingerprint.action())
      .then(() => {
        this._getPerson()
        this.modalToggle()
        KunjungToast({ title: 'Fingerprint Berhasil Disimpan' })
      })
      .catch(() => {
        this.modalToggle()
        KunjungToast({ title: 'Fingerprint gagal disimpan', icon: 'error' })
      })
  }

  getSubmitForm() {
    switch (this.state.modalType) {
      case 2:
        return this.onClickApprove()
      case 3:
        return this.onClickReject()
      case 4:
        return this.onClickClearFingerprint()
      case 5:
        return this.onClickClearFingerprint()
      default:
        return
    }
  }

  getModalTitle() {
    return 'View'
  }

  onClickView(person) {
    console.log(person)
    this.setState(
      {
        image: person.image,
        modalType: 1,
      },
      () => {
        this.modalToggle()
      }
    )
  }

  _getPerson() {
    this.getPerson.id = this.props.match.params.id
    this.props.dispatch(this.getPerson.action())
  }

  getModalType() {
    switch (this.state.modalType) {
      case 0:
        return <LoadingModal />
      case 1:
        return this.getModalImage()
      case 2:
        return <Question title="Apa anda yakin Accept User ini ?" />
      case 3:
        return <Question title="Apa anda yakin Decline User ini ?" />
      case 4:
        return <Question title="Apa anda yakin Reset Fingerprint User ini ?" />
      case 5:
        return (
          <Question title="Fingerprint terdeteksi di user lain apakah ingin reset fingerprint ?" />
        )
      default:
        break
    }
  }

  getModalImage() {
    return (
      <Container>
        <div
          className="d-flex justify-content-center mb-3"
          style={{
            maxWidth: '100%',
          }}
        >
          <img src={this.state.image} alt="" width="100%" />
        </div>
      </Container>
    )
  }

  componentDidMount() {
    this._getPerson()
  }
  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex">
            <Button
              disabled={this.props.person.finger_total >= 10}
              className="btn-icon-only"
              size="sm"
              style={{ fontSize: '20px' }}
              color=""
              onClick={() => {
                this.props.history.goBack()
              }}
            >
              <i className="fas fa-arrow-left"></i>
            </Button>
            <h3 className="mr-auto mb-0 mt-1">Approval</h3>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Photo</th>
                <th scope="col">Fingerprint</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{this.props.person.name}</td>
                <td className="d-flex flex-row-reverse justify-content-center">
                  {this.props.person.image.map((person, index) => (
                    <KunjungButton
                      className="btn-sm mr-3"
                      onClick={() => this.onClickView(person)}
                    >
                      {index === 0 ? 'New' : 'Old'}
                    </KunjungButton>
                  ))}
                </td>
                <td>
                  {this.props.person.finger_total}/10
                  <Button
                    disabled={this.props.person.finger_total >= 10}
                    className="ml-3 btn-icon-only text-light"
                    size="sm"
                    style={{ fontSize: '20px' }}
                    color=""
                    onClick={() => {
                      this.setState(
                        {
                          modalType: 0,
                        },
                        () => {
                          this.modalToggle()
                          this.onClickCaptureFingerprint()
                        }
                      )
                    }}
                  >
                    <i className="fa fa-plus-circle kunjung-text-primary"></i>
                  </Button>
                  {this.props.person.finger_total > 0 ? (
                    <Button
                      className="btn-icon-only text-light"
                      size="sm"
                      style={{ fontSize: '20px' }}
                      onClick={() =>
                        this.setState({ modalType: 4 }, () => {
                          this.modalToggle()
                        })
                      }
                      color=""
                    >
                      <i className="fa fa-times-circle kunjung-text-primary"></i>
                    </Button>
                  ) : null}
                </td>
                <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => {
                      this.setState({ modalType: 3 }, () => {
                        this.modalToggle()
                      })
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      this.setState({ modalType: 2 }, () => {
                        this.modalToggle()
                      })
                    }}
                  >
                    Approve
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <CardFooter className="py-4"></CardFooter>
        </Card>
        <KunjungModal
          className="modal-dialog-centered"
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle.bind(this)}
          title={<h2>{this.getModalTitle()}</h2>}
          componentFooter={
            this.state.modalType > 1 ? (
              <Container>
                <Row>
                  <Col>
                    <KunjungButton
                      outline
                      onClick={this.modalToggle.bind(this)}
                      block
                    >
                      Batal
                    </KunjungButton>
                  </Col>
                  <Col>
                    <KunjungButton
                      type="submit"
                      block
                      onClick={this.getSubmitForm.bind(this)}
                    >
                      Submit
                    </KunjungButton>
                  </Col>
                </Row>
              </Container>
            ) : null
          }
        >
          {this.getModalType()}
        </KunjungModal>
      </KunjungAdminLayout>
    )
  }
}

const mapStateTopProps = (state) => {
  return {
    person: state.person.detail,
  }
}

export default connect(mapStateTopProps)(Approval)
