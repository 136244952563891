import { Component } from 'react'

import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'
import KunjungAuthLayout from '../layout/KunjungAuthLayout'

import { kunjungAuthLayoutStyle } from '../layout/styles'

import { login } from '../../redux/actions/auth'
import { connect } from 'react-redux'
import KunjungToast from '../../components/Modal/Toast'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
    }
  }

  onSubmitLogin(e) {
    console.log(this.props)
    e.preventDefault()
    const self = this

    const data = {
      username: self.state.username,
      password: self.state.password,
    }
    console.log(this.props)
    this.props
      .dispatch(login(data))
      .then((res) => {
        if (res.value.data.status === 'error') {
          KunjungToast({
            icon: 'error',
            title: 'Username or Password is Wrong!',
          })
        }
      })
      .catch((error) => {
        KunjungToast({
          icon: 'error',
          title: error.message,
        })
      })
  }

  render() {
    return (
      <KunjungAuthLayout className="justify-content-center">
        <form
          method="POST"
          className={`${kunjungAuthLayoutStyle.formClass}`}
          onSubmitCapture={this.onSubmitLogin.bind(this)}
        >
          <section className={kunjungAuthLayoutStyle.copy}>
            <h2
              className={`${kunjungAuthLayoutStyle.h2Class} kunjung-text-primary`}
            >
              Login
            </h2>
          </section>
          <div
            className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.name}`}
          >
            <label className="kunjung-text-primary" htmlFor="fusername">
              {' '}
              Username :
            </label>
            <KunjungInput
              type="text"
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </div>

          <div
            className={`${kunjungAuthLayoutStyle.inputContainer} ${kunjungAuthLayoutStyle.password}`}
          >
            <label className="kunjung-text-primary" htmlFor="fpassword">
              {' '}
              Password :
            </label>
            <KunjungInput
              type="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </div>

          <KunjungButton
            type="submit"
            className={kunjungAuthLayoutStyle.signupBtn}
          >
            {this.state.isLoading ? 'loading' : 'Login'}
          </KunjungButton>
        </form>
      </KunjungAuthLayout>
    )
  }
}

export default connect()(Login)
