import { person } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  data: [],
  detail: {
    name: '',
    image: [],
  },
  page: {
    total: 0,
    page: 0,
  },
  notif: [],
}

const payloadFullfilled = (state, payload) => {
  if (!payload) {
    return state
  }
  state.isError = payload.data.status === 'error'

  const newState = {
    ...state,
    isLoading: false,
    message: state.isError === 'error' ? payload.data.message : '',
    data: state.isError === 'error' ? [] : payload.data.result,
    page: state.isError === 'error' ? [] : payload.data.page,
  }
  return newState
}

function personReducer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(person.GET_PERSON):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.GET_PERSON):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.GET_PERSON):
      return payloadFullfilled(state, action.payload)
    case pendingAction(person.GET_DETAIL):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.GET_DETAIL):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.GET_DETAIL):
      return {
        ...state,
        isLoading: false,
        isError: false,
        detail: action.payload.data.result,
      }

    case pendingAction(person.ADD_FACE):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.ADD_FACE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.ADD_FACE):
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case pendingAction(person.PERSON_APPROVE):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.PERSON_APPROVE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.PERSON_APPROVE):
      return {
        ...state,
        isLoading: false,
        isError: false,
      }

    case pendingAction(person.PERSON_REJECT):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.PERSON_REJECT):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.PERSON_REJECT):
      return {
        ...state,
        isLoading: false,
        isError: false,
      }

    case pendingAction(person.FINGERPRINT_CLEAR):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.FINGERPRINT_CLEAR):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.FINGERPRINT_CLEAR):
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case pendingAction(person.FINGERPRINT_CAPTURE):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.FINGERPRINT_CAPTURE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.FINGERPRINT_CAPTURE):
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case pendingAction(person.PERSON_NOTIF):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(person.PERSON_NOTIF):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.message,
      }
    case fullfilledAction(person.PERSON_NOTIF):
      return {
        ...state,
        isLoading: false,
        isError: false,
        notif: action.payload.data.result,
      }
    default:
      return state
  }
}

export default personReducer
