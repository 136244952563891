import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { admin } from '../../actionTypes'

class AddAdmin extends Action {
  constructor() {
    super()
    this.type = admin.ADD_ADMIN
    this.url = '/admin'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(AddAdmin).post(this.url, this.data, this.config),
    }
  }
}

export default AddAdmin
