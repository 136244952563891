import { auth } from '../actionTypes/'
import apiAdapter from '../helpers/apiAdapter'

export function login(data, config) {
  const body = {
    username: data.username,
    password: data.password,
  }

  return {
    type: auth.LOGIN,
    payload: apiAdapter(login).post('/auth/login', body, config),
  }
}

export function logout() {
  return { type: auth.LOGOUT }
}

const authActions = {
  login,
  logout,
}

export default authActions
