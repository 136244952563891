import { setting } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  data: [],
  detail: {},
}

function settingReducer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(setting.GET_SETTING):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(setting.GET_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError ? action.payload.message : 'Error',
      }
    case fullfilledAction(setting.GET_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        data: action.payload ? action.payload.data.result : [...state.data],
      }
    case pendingAction(setting.ADD_SETTING):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(setting.ADD_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload
          ? action.payload.isAxiosError
            ? action.payload.message
            : action.payload.data.message
          : 'Unknown Error!',
      }
    case fullfilledAction(setting.ADD_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(setting.EDIT_SETTING):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(setting.EDIT_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(setting.EDIT_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(setting.DELETE_SETTING):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(setting.DELETE_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.data.message,
      }
    case fullfilledAction(setting.DELETE_SETTING):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    default:
      return state
  }
}

export default settingReducer
