import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class ClearFingerprint extends Action {
  constructor() {
    super()
    this.type = person.FINGERPRINT_CLEAR
    this.url = '/person/fingerprint'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(ClearFingerprint).delete(this.uri, this.config),
    }
  }
}

export default ClearFingerprint
