import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import logger from 'redux-logger'
import promiseMiddleware from 'redux-promise-middleware'
import rootReducer from './reducers'

const { REACT_APP_ENVIRONMENT } = process.env

const persistConfig = { key: 'root', storage, whitelist: ['auth'] }

const persistedReducer = persistReducer(persistConfig, rootReducer)

const appliedMiddleware = () => {
  if (REACT_APP_ENVIRONMENT === 'production') {
    return applyMiddleware(promiseMiddleware)
  }

  return applyMiddleware(promiseMiddleware, logger)
}
export const store = createStore(persistedReducer, appliedMiddleware())

export const persistor = persistStore(store)

const applyStore = {
  store,
  persistor,
}

export default applyStore
