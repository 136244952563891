import { dashboard } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  card: [],
  chart: {
    labels: [],
    datasets: [],
  },
}

function dashboardReducer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(dashboard.CARD):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(dashboard.CARD):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(dashboard.CARD):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        card: action.payload ? action.payload.data.result : [],
      }
    case pendingAction(dashboard.CHART):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(dashboard.CHART):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(dashboard.CHART):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        chart: action.payload ? action.payload.data.result : [],
      }
    default:
      return state
  }
}

export default dashboardReducer
