import { auth } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  data: {
    token: null,
    refreshToken: null,
  },
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case pendingAction(auth.LOGIN):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(auth.LOGIN):
      return {
        ...state,
        isLoading: false,
        message: '',
        isError: true,
      }
    case fullfilledAction(auth.LOGIN):
      let payload = action.payload
      if (payload.data.status !== 'error') {
        return {
          ...state,
          isLoading: false,
          isError: false,
          message: '',
          data: {
            token: payload.data.result.token,
            refreshToken: payload.data.result.refreshToken,
          },
        }
      }
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: payload.data.message,
        data: {
          token: null,
          refreshToken: null,
        },
      }
    case pendingAction(auth.REFRESH_TOKEN):
      return {
        ...state,
        isLoading: true,
      }
    case rejectedAction(auth.REFRESH_TOKEN):
      return {
        ...state,
        isLoading: false,
        message: '',
        isError: true,
      }
    case fullfilledAction(auth.REFRESH_TOKEN):
      let payloads = action.payload
      console.log(action)
      if (payloads.data && payloads.data.status !== 'error') {
        return {
          ...state,
          isLoading: false,
          isError: false,
          message: '',
          data: {
            token: payloads.data.result.token,
            refreshToken: payloads.data.result.refreshToken,
          },
        }
      }
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: payloads.data.message,
        data: {
          token: null,
          refreshToken: null,
        },
      }
    case auth.LOGOUT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        data: {
          token: null,
          refreshToken: null,
        },
      }
    default:
      return state
  }
}

export default authReducer
