const person = {
  GET_PERSON: 'person/get',
  GET_DETAIL: 'person/detail',
  ADD_PERSON: 'person/add',
  EDIT_PERSON: 'person/edit',
  DELETE_PERSON: 'person/delete',
  ADD_FACE: 'person/face',
  PERSON_APPROVE: 'person/approve',
  PERSON_REJECT: 'person/reject',
  PERSON_NOTIF: 'person/notif',
  FINGERPRINT_CLEAR: 'person/fingerprint/clear',
  FINGERPRINT_CAPTURE: 'person/fingerprint/capture',
}

export default person
