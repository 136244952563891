import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { device } from '../../actionTypes'

class AddDevice extends Action {
  constructor() {
    super()
    this.type = device.ADD_DEVICE
    this.url = '/device'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(AddDevice).post(this.url, this.data, this.config),
    }
  }
}

export default AddDevice
