import React from 'react'
import { useLocation, Route, Switch } from 'react-router-dom'

import NavbarFactory from '../factory/NavbarFactory'

import Sidebar from '../components/Sidebar/Sidebar'

import RouteFactory from '../factory/RouteFacory'
import { verifyRole } from '../helper'
import { connect } from 'react-redux'

const { REACT_APP_CLIENT_NAME } = process.env

const getImage = () => {
  switch (REACT_APP_CLIENT_NAME) {
    case 'persahabatan':
      return require('../assets/img/brand/persahabatan.png').default

    default:
      return require('../assets/img/brand/Kunjung_Logo_(2).png').default
  }
}

const factoryNavbar = new NavbarFactory()
const AdminNavbar = factoryNavbar.create(REACT_APP_CLIENT_NAME)

const Admin = (props) => {
  const mainContent = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContent.current.scrollTop = 0
  }, [location])

  const route = new RouteFactory()
  const createdRoute = route.create(REACT_APP_CLIENT_NAME || 'kunjung')

  const getRoutes = () => {
    // eslint-disable-next-line array-callback-return
    let i = 0
    // eslint-disable-next-line array-callback-return
    return createdRoute.listRoutes.map((prop) => {
      if (
        prop.layout === '/admin' &&
        verifyRole(prop.roleAccess, props.auth.token)
      ) {
        getRouteChild(prop.child ? prop.child : [], i++)
        return (
          <Route
            path={prop.layout + prop.path}
            exact
            component={prop.component}
            key={i++}
          />
        )
      }
    })
  }

  const getRouteChild = (child = [], key) => {
    child.map((value) => {
      console.log(value)
      return (
        <Route path={value.path} exact component={value.component} key={key} />
      )
    })
  }

  const getBrandText = (path) => {
    for (let i = 0; i < createdRoute.listRoutes.length; i++) {
      if (
        props.location.pathname.indexOf(
          createdRoute.listRoutes[i].layout + createdRoute.listRoutes[i].path
        ) !== -1
      ) {
        return createdRoute.listRoutes[i].name
      }
    }
    return 'Brand'
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={createdRoute.listRoutes}
        logo={{
          innerLink: '/admin/index',
          imgSrc: getImage(),
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>{getRoutes()}</Switch>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
  }
}

export default connect(mapStateToProps)(Admin)
