import Factory from './Factory'
import Navbar from '../components/Navbars/AdminNavbar'
import PersahabatanNavbar from '../components/Navbars/PersahabatanNavbar'

class NavbarFactory extends Factory {
  create(params) {
    if (params === 'persahabatan') {
      return PersahabatanNavbar
    }
    return Navbar
  }
}

export default NavbarFactory
