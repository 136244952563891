import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap'
import PaginationComponent from 'react-paginate'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'
import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'
import KunjungModal from '../../components/Modal/Modal'

import { connect } from 'react-redux'
import GetPerson from '../../redux/actions/person/GetPerson'
import AddPerson from '../../redux/actions/person/AddPerson'
import { getStatusClassPerson, getStatusPerson } from '../../helper'
import DeleteBody from '../../components/Modal/Delete'
import { Link } from 'react-router-dom'
import EditPerson from '../../redux/actions/person/EditPerson'
import DeletePerson from '../../redux/actions/person/DeletePeson'
import KunjungToast from '../../components/Modal/Toast'
import Checkbox from 'react-custom-checkbox'
import status from '../../lib/persahabatan/status'

class Employee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      modalType: 3,
      nip: null,
      name: null,
      employeeNo: null,
      selectedId: null,
      page: 1,
      search: '',
      openDropdown: false,
      filterValue: new Array(status.length).fill(true),
    }

    this.getPerson = new GetPerson()
    this.addPerson = new AddPerson()
    this.deletePerson = new DeletePerson()
    this.editPerson = new EditPerson()
  }

  modalToggle() {
    this.setState((prevState) => {
      return {
        isModalOpen: !prevState.isModalOpen,
        nip: null,
        name: null,
        employeeNo: null,
        selectedId: null,
      }
    })
  }

  dropdownToggle() {
    this.setState((prevState) => {
      return {
        openDropdown: !prevState.openDropdown,
      }
    })
  }

  _getPerson() {
    this.props.dispatch(this.getPerson.action())
  }

  getModalTitle() {
    switch (this.state.modalType) {
      case 1:
        return 'Add Employee'
      case 2:
        return 'Edit Employee'
      case 3:
        return 'Delete Employee'
      default:
        return 'Add Employee'
    }
  }

  getModalType() {
    switch (this.state.modalType) {
      case 1:
        return this.getModalAdd()
      case 2:
        return this.getModalAdd()
      case 3:
        return this.getModalDelete()
      default:
        return this.getModalDelete()
    }
  }

  getModalDelete() {
    return <DeleteBody title="Apakah Anda Yakin ingin menghapus user ini ?" />
  }

  get data() {
    return {
      employeeNo: this.state.employeeNo,
      name: this.state.name,
      nip: this.state.nip,
    }
  }

  getSubmitForm() {
    switch (this.state.modalType) {
      case 1:
        return this.submitAdd()
      case 2:
        return this.submitEdit()
      case 3:
        return this.submitDelete()
      default:
        return this.submitAdd()
    }
  }

  submitAdd() {
    this.addPerson.data = this.data
    this.props.dispatch(this.addPerson.action()).then(() => {
      this._getPerson()
      this.modalToggle()
      KunjungToast({ title: 'Add Employee Success!' })
    })
  }

  submitDelete() {
    this.deletePerson.id = this.state.selectedId
    this.props.dispatch(this.deletePerson.action()).then(() => {
      this._getPerson()
      this.modalToggle()
      KunjungToast({ title: 'Employee Deleted!' })
    })
  }

  submitEdit() {
    this.editPerson.id = this.state.selectedId
    this.editPerson.data = this.data
    this.props.dispatch(this.editPerson.action()).then(() => {
      this._getPerson()
      this.modalToggle()
      KunjungToast({ title: 'Edit Employee Success!' })
    })
  }

  getModalAdd() {
    return (
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault()

            this.getSubmitForm()
          }}
          id="kunjung"
        >
          <FormGroup>
            <Label for="nip">NIP :</Label>
            <KunjungInput
              type="text"
              id="nip"
              onChange={(e) => this.setState({ nip: e.target.value })}
              value={this.state.nip}
            />
          </FormGroup>
          <FormGroup>
            <Label for="absen">Absen ID :</Label>
            <KunjungInput
              type="text"
              id="absen"
              onChange={(e) => this.setState({ employeeNo: e.target.value })}
              value={this.state.employeeNo}
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">Name :</Label>
            <KunjungInput
              type="text"
              id="name"
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
          </FormGroup>
        </Form>
      </Container>
    )
  }

  onModalDeleteClick(id) {
    this.setState(
      {
        modalType: 3,
      },
      () => {
        this.modalToggle()
        this.setState({ selectedId: id })
      }
    )
  }

  get params() {
    let indexes = []
    // eslint-disable-next-line array-callback-return
    this.state.filterValue.map((value, index) => {
      if (value) {
        indexes.push(status[index].id)
      }
    })

    return {
      page: this.state.page,
      search: this.state.search,
      status: indexes,
    }
  }

  onModalEditClick(person) {
    this.setState(
      {
        modalType: 2,
      },
      () => {
        this.modalToggle()
        this.setState({
          nip: person.nip,
          selectedId: person.id,
          name: person.name,
          employeeNo: person.employeeNo,
        })
      }
    )
  }

  onClickAdd() {
    this.setState({ modalType: 1 }, () => {
      this.modalToggle()
    })
  }

  onPageChange(e) {
    console.log(e)
    this.setState(
      {
        page: e.selected + 1,
      },
      () => {
        this.getPerson.params = this.params
        this._getPerson()
      }
    )
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.getPerson.params = this.params
      this._getPerson()
    }
  }

  componentDidMount() {
    this._getPerson()
  }
  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex">
            <h3 className="mr-auto mb-0">Employee</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <KunjungInput
                className="kunjung-search"
                bsSize="sm"
                placeholder="Search here..."
                onChange={(e) => this.setState({ search: e.target.value })}
                onKeyDown={(e) => this.onKeyEnter(e)}
              />
            </form>
            <Dropdown
              isOpen={this.state.openDropdown}
              toggle={this.dropdownToggle.bind(this)}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={this.state.openDropdown}
              >
                <KunjungButton className="ml-2" size="sm" outline>
                  <i className="fas fa-filter"></i>
                </KunjungButton>
              </DropdownToggle>
              <DropdownMenu className="p-3">
                {status.map((s, index) => {
                  return (
                    <div>
                      <Checkbox
                        label={s.label}
                        labelStyle={{
                          marginLeft: '10px',
                        }}
                        borderColor="#73008A"
                        checked={this.state.filterValue[index]}
                        onChange={(e) =>
                          this.setState(
                            (prevState) => {
                              let filter = prevState.filterValue

                              filter[index] = e
                              return {
                                filterValue: filter,
                              }
                            },
                            () => {
                              this.getPerson.params = this.params
                              this.props.dispatch(this.getPerson.action())
                            }
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          backgroundColor: this.state.filterValue[index]
                            ? '#73008A'
                            : 'white',
                        }}
                        icon={
                          <i
                            className="fas fa-check text-white"
                            style={{ fontSize: '14px' }}
                          ></i>
                        }
                      />
                    </div>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
            <KunjungButton
              className="ml-2"
              size="sm"
              onClick={this.onClickAdd.bind(this)}
            >
              Add Employee
            </KunjungButton>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">NIP</th>
                <th scope="col">Name</th>
                <th scope="col">Absent ID</th>

                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.person.isLoading ? (
                <tr>
                  <td colSpan="7">
                    <Spinner type="grow" color="primary" />
                  </td>
                </tr>
              ) : (
                this.props.persons.map((person, index) => (
                  <>
                    <tr>
                      <th scope="row">
                        {this.state.page * 10 + index + 1 - 10}
                      </th>
                      <td>{person.nip}</td>
                      <td>{person.name}</td>
                      <td>{person.employeeNo}</td>
                      <td>
                        <Badge
                          className={`${getStatusClassPerson(
                            person.status
                          )} text-white badge-lg`}
                          style={{
                            minWidth: '100px',
                            fontSize: '80%',
                          }}
                        >
                          {getStatusPerson(person.status)}
                        </Badge>
                      </td>
                      <td>
                        <Link to={`/admin/employees/${person.id}`}>
                          <Button
                            className="btn-icon-only text-light"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-user-check kunjung-text-primary"></i>
                          </Button>
                        </Link>
                        <Button
                          className="btn-icon-only text-light"
                          size="sm"
                          color=""
                          disabled={person.status === 4}
                          onClick={() => this.onModalEditClick(person)}
                        >
                          <i className="far fa-edit kunjung-text-primary"></i>
                        </Button>

                        <Button
                          className="btn-icon-only text-light"
                          size="sm"
                          color=""
                          onClick={() => this.onModalDeleteClick(person.id)}
                        >
                          <i className="far fa-trash-alt kunjung-text-primary"></i>
                        </Button>
                      </td>
                    </tr>
                  </>
                ))
              )}
            </tbody>
          </Table>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <nav
                className="pagination justify-content-end mb-0"
                aria-label="pagination"
              >
                <PaginationComponent
                  pageCount={this.props.page.total}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  initialPage={this.state.page - 1}
                  forcePage={this.state.page - 1}
                  onPageChange={(e) => this.onPageChange(e)}
                  previousLabel={<i className="fas fa-caret-left"></i>}
                  nextLabel={<i className="fas fa-caret-right"></i>}
                  containerClassName="justify-content-end mb-0 pagination"
                  breakClassName="mt-1 mr-1 ml-1"
                  pageClassName="page-item"
                  activeClassName="active text-white"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link kunjung-text-primary"
                  nextLinkClassName="page-link kunjung-text-primary"
                  pageLinkClassName="page-link kunjung-text-primary"
                  disabledClassName="disable"
                />
              </nav>
            </nav>
          </CardFooter>
        </Card>
        <KunjungModal
          className="modal-dialog-centered"
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle.bind(this)}
          title={<h2>{this.getModalTitle()}</h2>}
          componentFooter={
            <Container>
              <Row>
                <Col>
                  <KunjungButton
                    outline
                    onClick={this.modalToggle.bind(this)}
                    block
                  >
                    Batal
                  </KunjungButton>
                </Col>
                <Col>
                  <KunjungButton
                    type="submit"
                    block
                    form="kunjung"
                    onClick={(e) => {
                      if (
                        this.state.modalType != 1 &&
                        this.state.modalType != 2
                      ) {
                        this.getSubmitForm()
                      }
                    }}
                  >
                    {this.props.person.isLoading ? (
                      <i class="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      'Submit'
                    )}
                  </KunjungButton>
                </Col>
              </Row>
            </Container>
          }
        >
          {this.getModalType()}
        </KunjungModal>
      </KunjungAdminLayout>
    )
  }
}

const mapStateTopProps = (state) => {
  return {
    persons: state.person.data,
    page: state.person.page,
    person: state.person,
  }
}

export default connect(mapStateTopProps)(Employee)
