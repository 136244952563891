class Action {
  constructor() {
    this.type = ''
    this.config = {}
    this.body = {}
    this.url = '/'
    this.id = null
  }

  set headers(config) {
    this.config = {
      ...this.config,
      headers: config,
    }
  }

  set params(config) {
    this.config = {
      ...this.config,
      params: config,
    }
  }

  set data(body) {
    this.body = body
  }

  get data() {
    if (this.config.data) {
      return JSON.parse(this.config.data)
    }
    return this.body
  }

  get uri() {
    if (this.config.url) {
      return this.config.url
    }
    return this.url + '/' + this.id
  }

  action() {
    return {
      type: this.type,
    }
  }

  get() {}
  post() {}
  put() {}
  delete() {}
}

export default Action
