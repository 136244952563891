import KunjungRegistration from '../views/kunjung/Registration'
import PersahabatanRegistration from '../views/persahabatan/Registration'
import Factory from './Factory'

class RegistrationFactory extends Factory {
  create(params) {
    if (params === 'persahabatan') {
      return PersahabatanRegistration
    }

    return KunjungRegistration
  }
}

export default RegistrationFactory
