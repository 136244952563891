import { device } from '../actionTypes'
import { pendingAction, rejectedAction, fullfilledAction } from '../helpers'

const inititalState = {
  isLoading: false,
  isError: false,
  message: '',
  data: [],
  detail: {},
}

function deviceRecucer(state = inititalState, action) {
  switch (action.type) {
    case pendingAction(device.GET_DEVICE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(device.GET_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(device.GET_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
        data: action.payload ? action.payload.data.result : [],
      }
    case pendingAction(device.ADD_DEVICE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(device.ADD_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(device.ADD_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(device.SYNC_DEVICE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(device.SYNC_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(device.SYNC_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(device.EDIT_DEVICE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(device.EDIT_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(device.EDIT_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    case pendingAction(device.DELETE_DEVICE):
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case rejectedAction(device.DELETE_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload.isAxiosError
          ? action.payload.message
          : action.payload.data.message,
      }
    case fullfilledAction(device.DELETE_DEVICE):
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: '',
      }
    default:
      return state
  }
}

export default deviceRecucer
