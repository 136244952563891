import React, { Component } from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

class KunjungButton extends Component {
  render() {
    return (
      <Button
        {...this.props}
        className={`kunjung-button ${
          this.props.outline
            ? 'kunjung-button-primary-outline'
            : 'kunjung-button-primary'
        }${this.props.className ? ' ' + this.props.className : ''}`}
      >
        {this.props.children}
      </Button>
    )
  }
}

const propTypes = {
  size: PropTypes.string,
  onclick: PropTypes.func,
  children: PropTypes.node,
  outline: PropTypes.bool,
}

const defaultProps = {
  outline: false,
}

KunjungButton.propTypes = propTypes
KunjungButton.defaultProps = defaultProps

export default KunjungButton
