import { combineReducers } from 'redux'
import authReducer from './auth'
import deviceRecucer from './device'
import personReducer from './person'
import adminRecucer from './admin'
import attendanceReducer from './attendance'
import dashboardReducer from './dashboard'
import settingReducer from './setting'

const combined = combineReducers({
  auth: authReducer,
  admin: adminRecucer,
  device: deviceRecucer,
  person: personReducer,
  attendance: attendanceReducer,
  dashboard: dashboardReducer,
  setting: settingReducer,
})

export default combined
