import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class GetPerson extends Action {
  constructor() {
    super()
    this.type = person.GET_PERSON
    this.url = '/person'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetPerson).get(this.url, this.config),
    }
  }
}

export default GetPerson
