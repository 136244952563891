import Action from '../Action'
import { device } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'

class GetDevice extends Action {
  constructor() {
    super()
    this.type = device.GET_DEVICE
    this.url = '/device'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetDevice).get(this.url, this.config),
    }
  }
}

export default GetDevice
