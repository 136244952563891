export function pendingAction(actionType) {
  return actionType + '_PENDING'
}

export function rejectedAction(actionType) {
  return actionType + '_REJECTED'
}

export function fullfilledAction(actionType) {
  return actionType + '_FULFILLED'
}

const helpers = {
  pendingAction,
  rejectedAction,
  fullfilledAction,
}

export default helpers
