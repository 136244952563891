import React from 'react'

import Chart from 'chart.js'

import { parseOptions, chartOptions } from '../variables/charts'

import { Line } from 'react-chartjs-2'
import { Card, CardHeader, CardBody } from 'reactstrap'

import KunjungAdminLayout from './layout/KunjungAdminLayout'

import HeaderFactory from '../factory/HeaderFactory'
import { Component } from 'react'
import { connect } from 'react-redux'
import GetAttendaceChart from '../redux/actions/dashboard/GetChart'
import moment from 'moment'
const header = new HeaderFactory()

const Header = header.create()

class Index extends Component {
  constructor(props) {
    super(props)
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }
    this.getChart = new GetAttendaceChart()
  }
  _getChart() {
    this.props.dispatch(this.getChart.action())
  }

  componentDidMount() {
    this._getChart()
  }

  render() {
    return (
      <KunjungAdminLayout>
        <Header />

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-transparent d-flex">
                  <div className="mr-auto">
                    <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                    <h2 className="mb-0">Attendance</h2>
                  </div>
                  <div className="kunjung-attendance-date">
                    <h5 className="text-uppercase ls-1 mb-1">Date</h5>
                    <h4 className="mb-0">
                      {moment().subtract(7, 'day').format('DD-MM-YYYY')} ~{' '}
                      {moment().format('DD-MM-YYYY')}
                    </h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line data={this.props.chart} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </KunjungAdminLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    chart: state.dashboard.chart,
  }
}

export default connect(mapStateToProps)(Index)
