const status = [
  {
    id: 1,
    label: 'New',
  },
  {
    id: 2,
    label: 'Waiting',
  },
  {
    id: 3,
    label: 'Update',
  },
  {
    id: 4,
    label: 'Approved',
  },
  {
    id: 5,
    label: 'Rejected',
  },
]

export default status
