import Route from './Route'

import Index from '../views/Index'
import Admin from '../views/kunjung/Admin'
import Device from '../views/kunjung/Device'
import Employee from '../views/persahabatan/Employee'
import Attendance from '../views/kunjung/Attendance'
import { ADMIN, SUPER_ADMIN } from '../lib/enum'
import Approval from '../views/persahabatan/Approval'

class PersahabatanRoute extends Route {
  constructor() {
    super()
    this.routes = [
      {
        path: '/index',
        name: 'Dashboard',
        icon: 'ni ni-tv-2',
        component: Index,
        layout: '/admin',
        roleAccess: [ADMIN, SUPER_ADMIN],
      },
      {
        path: '/attendances',
        name: 'Attendances',
        icon: 'ni ni-badge',
        component: Attendance,
        layout: '/admin',
        roleAccess: [ADMIN, SUPER_ADMIN],
      },
      {
        path: '/employees',
        name: 'Employees',
        icon: 'ni ni-single-02',
        component: Employee,
        layout: '/admin',
        roleAccess: [ADMIN, SUPER_ADMIN],
      },
      {
        path: '/employees/:id',
        name: 'Approval',
        layout: '/admin',
        icon: 'ni ni-single-02',
        roleAccess: [ADMIN, SUPER_ADMIN],
        component: Approval,
        isNotSidebar: true,
      },
      {
        path: '/device',
        name: 'Device',
        icon: 'ni ni-mobile-button',
        component: Device,
        layout: '/admin',
        roleAccess: [ADMIN, SUPER_ADMIN],
      },
      {
        path: '/admin',
        name: 'Admin',
        icon: 'ni ni-circle-08',
        component: Admin,
        layout: '/admin',
        roleAccess: [SUPER_ADMIN],
      },
    ]
  }
}

export default PersahabatanRoute
