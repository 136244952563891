/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'
import GetCard from '../../redux/actions/dashboard/GetCard'

class Header extends Component {
  constructor() {
    super()
    this.card = new GetCard()
  }

  _getCard() {
    this.props.dispatch(this.card.action())
  }
  componentDidMount() {
    this._getCard()
  }
  render() {
    return (
      <>
        <div className="header pb-5">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                {this.props.card.map((card) => (
                  <Col lg="6" xl="4">
                    <Card className="card-stats kunjung-card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              style={{ minHeight: '50px' }}
                              className="text-uppercase text-muted mb-0"
                            >
                              {card.name}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {card.data}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape kunjung-bg-active kunjung-text-primary rounded-circle shadow">
                              <i className={card.icon || 'fas fa-chart-bar'} />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

const mapStateTopProps = (state) => ({
  card: state.dashboard.card,
})
export default connect(mapStateTopProps)(Header)
