import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class AddFace extends Action {
  constructor() {
    super()
    this.type = person.ADD_PERSON
    this.url = '/person/face'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(AddFace).post(this.url, this.data, this.config),
    }
  }
}

export default AddFace
