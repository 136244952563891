import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class PersonReject extends Action {
  constructor() {
    super()
    this.type = person.PERSON_REJECT
    this.url = '/person/reject'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(PersonReject).post(this.url, this.data, this.config),
    }
  }
}

export default PersonReject
