import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from 'reactstrap'
import PaginationComponent from 'react-paginate'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'
import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'
import KunjungMultiDatePicker from '../../components/Form/MultiDatePicker'
import KunjungModal from '../../components/Modal/Modal'
import { connect } from 'react-redux'
import GetAttendace from '../../redux/actions/attendance/GetAttendance'
import Checkbox from 'react-custom-checkbox'

import moment from 'moment'

const dataDropdown = [
  {
    label: 'User',
    id: 'user',
  },
  {
    label: 'Stranger',
    id: 'stranger',
  },
]

class Attendance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      image: [],
      modalType: 1,
      value: [],
      page: 1,
      search: '',
      filterValue: new Array(dataDropdown.length).fill(true, 0, 1),
    }
    this.getAttendance = new GetAttendace()
    this.getAttendance.params = this.params
  }

  _getAttendance() {
    this.props.dispatch(this.getAttendance.action())
  }

  toggleOpen() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    })
  }

  dropdownToggle() {
    this.setState((prevState) => {
      return {
        openDropdown: !prevState.openDropdown,
      }
    })
  }

  getFaceModal() {
    return (
      <Container>
        {this.state.image.map((image) => (
          <div className="d-flex justify-content-center mb-3">
            <img
              src={image.data}
              alt=""
              style={{
                maxWidth: '100%',
              }}
            />
          </div>
        ))}
      </Container>
    )
  }
  onView(data) {
    const modal = data.verify_mode === 'fingerprint' ? 1 : 2
    this.setState(
      {
        modalType: modal,
        image: data.image,
      },
      () => {
        this.toggleOpen()
      }
    )
  }

  onChangePicker(e) {
    this.setState({ value: e }, () => {
      console.log(this.state.value[0])
      if (e.length === 2) {
        this.getAttendance.params = this.params
        this._getAttendance()
      }
    })
  }

  get params() {
    let indexes = []
    // eslint-disable-next-line array-callback-return
    this.state.filterValue.map((value, index) => {
      if (value) {
        indexes.push(dataDropdown[index].id)
      }
    })
    const date = {}

    if (this.state.value) {
      if (this.state.value[0]) {
        date.startDate = moment(new Date(this.state.value[0])).format(
          'YYYY-MM-DD'
        )
      }

      if (this.state.value[1]) {
        date.endDate = moment(new Date(this.state.value[1])).format(
          'YYYY-MM-DD'
        )
      }
    }

    return {
      ...date,
      search: this.state.search,
      page: this.state.page,
      show: indexes,
    }
  }

  getModalBody() {
    switch (this.state.modalType) {
      case 1:
        return this.getFingerprint()
      case 2:
        return this.getFaceModal()
      default:
        return this.getFingerprint()
    }
  }

  getFingerprint() {
    return (
      <Container>
        <div className="d-flex justify-content-center flex-column">
          <img
            src={require('../../assets/img/vector/pic_finger 1.png').default}
            alt="pic_finger 1.png"
          />
          <h3 className="mt-3 text-center">
            Attendences Success Using Fingerprint
          </h3>
        </div>
      </Container>
    )
  }

  onPageChange(e) {
    console.log(e)
    this.setState(
      {
        page: e.selected + 1,
      },
      () => {
        this.getAttendance.params = this.params
        this._getAttendance()
      }
    )
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.getAttendance.params = this.params
      this._getAttendance()
    }
  }

  componentDidMount() {
    this._getAttendance()
  }

  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex">
            <h3 className="mr-auto mb-0">Attendance Management</h3>

            <Dropdown
              isOpen={this.state.openDropdown}
              toggle={this.dropdownToggle.bind(this)}
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={this.state.openDropdown}
              >
                <KunjungButton className="mr-2" size="sm" outline>
                  <i className="fas fa-filter"></i>
                </KunjungButton>
              </DropdownToggle>
              <DropdownMenu className="p-3">
                {dataDropdown.map((s, index) => {
                  return (
                    <div>
                      <Checkbox
                        label={s.label}
                        labelStyle={{
                          marginLeft: '10px',
                        }}
                        borderColor="#73008A"
                        checked={this.state.filterValue[index]}
                        onChange={(e) =>
                          this.setState(
                            (prevState) => {
                              let filter = prevState.filterValue

                              filter[index] = e
                              return {
                                filterValue: filter,
                              }
                            },
                            () => {
                              this.getAttendance.params = this.params
                              this.props.dispatch(this.getAttendance.action())
                            }
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          backgroundColor: this.state.filterValue[index]
                            ? '#73008A'
                            : 'white',
                        }}
                        icon={
                          <i
                            className="fas fa-check text-white"
                            style={{ fontSize: '14px' }}
                          ></i>
                        }
                      />
                    </div>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
            <form onSubmit={(e) => e.preventDefault()}>
              <KunjungMultiDatePicker
                className="kunjung-text-primary"
                inputclassName="kunjung-search"
                placeholder="Start Date ~ End Date"
                format="DD MMM YYYY"
                range
                onChange={(e) => this.onChangePicker(e)}
                value={this.state.value}
              />
            </form>
            <form onSubmit={(e) => e.preventDefault()} className="ml-2">
              <KunjungInput
                className="kunjung-search"
                bsSize="sm"
                placeholder="Search here..."
                onKeyDown={(e) => this.onKeyEnter(e)}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
            </form>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Time</th>
                <th scope="col">Place</th>
                <th scope="col">Employee</th>
                <th scope="col">Type</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.attendance.isLoading ? (
                <tr>
                  <td colSpan="7">
                    <Spinner type="grow" color="primary" />
                  </td>
                </tr>
              ) : (
                this.props.attendance.data.map((attendance, index) => (
                  <tr>
                    <th scope="row">{this.state.page * 10 + index + 1 - 10}</th>
                    <td>
                      {moment(attendance.datetime).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </td>
                    <td>
                      {attendance.Device
                        ? attendance.Device.place
                        : attendance.host}
                    </td>
                    <th>{attendance.person_name}</th>
                    <th scope="row">{attendance.verify_mode}</th>
                    <td>
                      <Badge color="" className="badge-dot">
                        <i className="bg-success" />
                        {attendance.status}
                      </Badge>
                    </td>
                    <td>
                      <KunjungButton
                        size="sm"
                        onClick={() => this.onView(attendance)}
                      >
                        View
                      </KunjungButton>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <CardFooter className="py-4">
            <nav aria-label="...">
              <nav
                className="pagination justify-content-end mb-0"
                aria-label="pagination"
              >
                <PaginationComponent
                  pageCount={this.props.attendance.page.total}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  initialPage={this.state.page - 1}
                  forcePage={this.state.page - 1}
                  onPageChange={(e) => this.onPageChange(e)}
                  previousLabel={<i className="fas fa-caret-left"></i>}
                  nextLabel={<i className="fas fa-caret-right"></i>}
                  containerClassName="justify-content-end mb-0 pagination"
                  breakClassName="mt-1 mr-1 ml-1"
                  pageClassName="page-item"
                  activeClassName="active text-white"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link kunjung-text-primary"
                  nextLinkClassName="page-link kunjung-text-primary"
                  pageLinkClassName="page-link kunjung-text-primary"
                  disabledClassName="disable"
                />
              </nav>
            </nav>
          </CardFooter>
        </Card>
        <KunjungModal
          className="modal-md"
          isOpen={this.state.isModalOpen}
          toggle={this.toggleOpen.bind(this)}
          title={<h3>View</h3>}
        >
          {this.getModalBody()}
        </KunjungModal>
      </KunjungAdminLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    attendance: state.attendance,
  }
}

export default connect(mapStateToProps)(Attendance)
