import axios from 'axios'
import auth from '../actionTypes/auth'

import { store } from '../store'

import Action from '../actions/Action'

const { dispatch } = store
const { REACT_APP_URL_MANAGEMENT } = process.env

function apiAdapter(Obj = Action, config) {
  const state = store.getState().auth

  const axiosInstance = axios.create({
    baseURL: REACT_APP_URL_MANAGEMENT,
    headers: {
      Authorization: state.data.token,
    },
    ...config,
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      return new Promise((resolve) => {
        resolve(response)
      })
    },

    async (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }

      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }

      if (!state.data.refreshToken) {
        return dispatch({
          type: auth.LOGOUT,
        })
      }

      return new Promise((resolve, reject) => {
        const payload = axiosInstance
          .post('/auth/token', {
            token: state.data.refreshToken,
          })
          .then((res) => {
            if (res.data.status === 'success') {
              const config = error.response.config

              console.log(config)
              const obj = new Obj()
              obj.config = config
              obj.headers = {
                Authorization: res.data.result.token,
              }

              dispatch(obj.action()).then((response) => {
                resolve(response.value)
              })
            }

            return res
          })
          .catch((err) => {
            reject(error)
            return err
          })

        dispatch({
          type: auth.REFRESH_TOKEN,
          payload: payload,
        })
      })
    }
  )

  return axiosInstance
}

export default apiAdapter
