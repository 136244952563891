import { Component } from 'react'

import React from 'react'

import { Container, Row } from 'reactstrap'
import { connect } from 'react-redux'

class KunjungAdminLayout extends Component {
  render() {
    return (
      <>
        <Container
          className="kunjung-bg-primary pb-8 pt-5 pt-md-8"
          fluid
          style={{
            minHeight: '100vh',
          }}
        >
          {/* Table */}
          <Row>
            <div className="col">{this.props.children}</div>
          </Row>
          <Row>
            <div
              className="col mt-5 d-flex justify-content-end"
              style={{
                height: '30px',
              }}
            >
              <img
                src={require('../../assets/img/brand/admin-footer.png').default}
                alt=""
              />
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default connect()(KunjungAdminLayout)
