import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import './assets/css/kunjung-dashboard.css'
import './assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/scss/argon-dashboard-react.scss'
import 'react-html5-camera-photo/build/css/index.css'

import { Provider } from 'react-redux'
import storage from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
const { store, persistor } = storage

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
