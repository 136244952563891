import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { device } from '../../actionTypes'

class SyncDevice extends Action {
  constructor() {
    super()
    this.type = device.SYNC_DEVICE
    this.url = '/device/sync'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(SyncDevice).put(this.uri, this.config),
    }
  }
}

export default SyncDevice
