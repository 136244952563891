import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { device } from '../../actionTypes'

class EditDevice extends Action {
  constructor() {
    super()
    this.type = device.EDIT_DEVICE
    this.url = '/device'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(EditDevice).put(this.uri, this.data, this.config),
    }
  }
}

export default EditDevice
