import { Component } from 'react'
import { kunjungAuthLayoutStyle } from './styles'

const { REACT_APP_CLIENT_NAME } = process.env

const getImage = () => {
  console.log(REACT_APP_CLIENT_NAME)
  switch (REACT_APP_CLIENT_NAME) {
    case 'persahabatan':
      return require('../../assets/img/brand/persahabatan.png').default

    default:
      return require('../../assets/img/brand/Kunjung_Logo_(2).png').default
  }
}

class KunjungAuthLayout extends Component {
  render() {
    return (
      <div className={kunjungAuthLayoutStyle.bodyClass}>
        <div className={kunjungAuthLayoutStyle.splitScreen}>
          <div className={kunjungAuthLayoutStyle.left}>
            <section className={kunjungAuthLayoutStyle.copy}>
              <img src={getImage()} alt="" />
            </section>
          </div>
          <div className={kunjungAuthLayoutStyle.right}>
            <div
              className={`${kunjungAuthLayoutStyle.content} ${this.props.className}`}
            >
              {this.props.children}
              <div className="footer-auth mb-3 mt-5">
                <img
                  src={
                    require('../../assets/img/brand/auth-footer.png').default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default KunjungAuthLayout
