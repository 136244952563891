import React, { Component } from 'react'
import DatePicker from 'react-multi-date-picker'

class KunjungMultiDatePicker extends Component {
  render() {
    return (
      <DatePicker
        {...this.props}
        inputClass={`form-control ${
          this.props.md ? '' : 'form-control-sm'
        } kunjung-form-input ${
          this.props.loading
            ? 'kunjung-loader'
            : this.props.timeOnly
            ? ''
            : 'kunjung-calendar'
        }`}
        className={this.props.class}
      />
    )
  }
}

const defaultProps = {
  outline: false,
}

KunjungMultiDatePicker.propTypes = DatePicker.PropTypes
KunjungMultiDatePicker.defaultProps = defaultProps

export default KunjungMultiDatePicker
