import jwtDecode from 'jwt-decode'
import { store } from '../redux/store'
import { ADMIN, SUPER_ADMIN } from '../lib/enum'

export function verifyRole(role = []) {
  const state = store.getState()
  const token = state.auth.data.token

  if (token.length === 0) {
    return false
  }

  const decoded = jwtDecode(token)

  if (role.includes(decoded.data.role)) {
    return true
  }

  return false
}

export function getNameAdmin() {
  const state = store.getState()
  const token = state.auth.data.token

  if (!token) {
    setTimeout(() => {
      const decoded = jwtDecode(token)
      return decoded.data.name
    }, 1000)
  }
  const decoded = jwtDecode(token)

  return decoded.data.name
}

export function getStatusPerson(status) {
  switch (status) {
    case 1:
      return 'New'
    case 2:
      return 'Waiting'
    case 3:
      return 'Update'
    case 4:
      return 'Approved'
    case 5:
      return 'Rejected'
    default:
      return ''
  }
}

export function getStatusClassPerson(status) {
  switch (status) {
    case 1:
      return 'bg-primary'
    case 2:
      return 'bg-warning'
    case 3:
      return 'kunjung-bg-primary'
    case 4:
      return 'bg-success'
    case 5:
      return 'bg-danger'
    default:
      return 'bg-primary'
  }
}

export function getRoleName(role) {
  switch (role) {
    case ADMIN:
      return 'Admin'

    case SUPER_ADMIN:
      return 'Super Admin'

    default:
      return 'Admin'
  }
}

export function imgSync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const helpers = {
  verifyRole,
  getNameAdmin,
  getStatusClassPerson,
  getStatusPerson,
}

export default helpers
