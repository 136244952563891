import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class DeletePerson extends Action {
  constructor() {
    super()
    this.type = person.DELETE_PERSON
    this.url = '/person'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(DeletePerson).delete(this.uri, this.config),
    }
  }
}

export default DeletePerson
