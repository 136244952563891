import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class CaptureFingerprint extends Action {
  constructor() {
    super()
    this.type = person.FINGERPRINT_CAPTURE
    this.url = '/person/fingerprint'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(CaptureFingerprint).post(
        this.url,
        this.data,
        this.config
      ),
    }
  }
}

export default CaptureFingerprint
