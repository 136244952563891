/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from 'react'
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
// nodejs library to set properties for components
import { PropTypes } from 'prop-types'

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap'
import { getStatusPerson, verifyRole } from '../../helper'

import { logout } from '../../redux/actions/auth'
import { connect } from 'react-redux'

var ps

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState()
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data)
  }
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false)
  }
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (verifyRole(prop.roleAccess, props.auth.token) && !prop.isNotSidebar) {
        return (
          <NavItem key={key}>
            <Container>
              <NavLink
                className={'kunjung-nav-link'}
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="kunjung-active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </Container>
          </NavItem>
        )
      }
    })
  }

  const { bgColor, routes, logo } = props
  let navbarBrandProps
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    }
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    }
  }

  const onLogout = (e) => {
    e.preventDefault()
    props.logout()
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              {props.notif.map((notif) => (
                <DropdownItem tag={Link} to="/admin/employees">
                  <span>
                    {notif.total} Employee {getStatusPerson(notif.status)}
                  </span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require('../../assets/img/theme/team-1-800x800.jpg')
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>
            {createLinks(routes)}
            <DropdownItem divider className="d-block d-lg-none d-md-none" />
            <NavItem className="d-block d-lg-none d-md-none">
              <Container>
                <NavLink
                  className={'kunjung-nav-link'}
                  to="/admin/logout"
                  tag={NavLinkRRD}
                  onClick={onLogout}
                >
                  <i className="fas fa-sign-out-alt"></i>
                  Logout
                </NavLink>
              </Container>
            </NavItem>
          </Nav>
        </Collapse>
        <Nav navbar className="d-none d-lg-block d-xl-block">
          <NavItem>
            <Container>
              <NavLink
                className={'kunjung-nav-link'}
                to="/admin/logout"
                tag={NavLinkRRD}
                onClick={onLogout}
              >
                <i className="fas fa-sign-out-alt"></i>
                Logout
              </NavLink>
            </Container>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  )
}

Sidebar.defaultProps = {
  routes: [{}],
}

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
}

const mapDispatchToProps = {
  logout,
}

export default connect((state) => {
  return {
    auth: state.auth.data,
    notif: state.person.notif,
  }
}, mapDispatchToProps)(Sidebar)
