import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { person } from '../../actionTypes'

class DetailPerson extends Action {
  constructor() {
    super()
    this.type = person.GET_DETAIL
    this.url = '/person'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(DetailPerson).get(this.uri, this.config),
    }
  }
}

export default DetailPerson
