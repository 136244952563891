import Action from '../Action'
import { dashboard } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'

class GetAttendaceChart extends Action {
  constructor() {
    super()
    this.type = dashboard.CHART
    this.url = '/dashboard/chart'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetAttendaceChart).get(this.url, this.config),
    }
  }
}

export default GetAttendaceChart
