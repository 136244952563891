import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Button,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
} from 'reactstrap'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'
import KunjungButton from '../../components/Button/Button'
import KunjungInput from '../../components/Form/Input'

import GetSetting from '../../redux/actions/setting/GetSetting'

import { connect } from 'react-redux'

import { getRoleName } from '../../helper'
import KunjungModal from '../../components/Modal/Modal'
import KunjungToast from '../../components/Modal/Toast'
import AddAdmin from '../../redux/actions/admin/AddAdmin'
import EditAdmin from '../../redux/actions/admin/EditAdmin'
import DeleteAdmin from '../../redux/actions/admin/DeleteAdmin'
import DeleteBody from '../../components/Modal/Delete'

class Admin extends Component {
  constructor() {
    super()
    this.getSetting = new GetSetting()
    this.addAdmin = new AddAdmin()
    this.editAdmin = new EditAdmin()
    this.deleteAdmin = new DeleteAdmin()

    this.state = {
      page: 1,
      selectedId: '',
      name: '',
      username: '',
      password: '',
      searchValue: '',
    }
  }

  _getSetting() {
    this.props.dispatch(this.getSetting.action())
  }

  get params() {
    return {
      page: 1,
      search: this.state.searchValue,
    }
  }

  getModalTitle() {
    switch (this.state.modalType) {
      case 1:
        return 'Add Admin'
      case 2:
        return 'Edit Admin'
      case 3:
        return 'Delete Admin'
      default:
        return 'Add Admin'
    }
  }

  onPageChange(e) {
    console.log(e)
    this.setState(
      {
        page: e.selected + 1,
      },
      () => {
        this.getSetting.params = this.params
        this._getSetting()
      }
    )
  }

  onModalEditClick(admin) {
    this.setState(
      {
        modalType: 2,
      },
      () => {
        this.modalToggle()
        this.setState({
          selectedId: admin.id,
          name: admin.name,
          username: admin.username,
        })
      }
    )
  }

  modalToggle() {
    this.setState((prevState) => {
      return {
        isModalOpen: !prevState.isModalOpen,
        name: '',
        username: '',
        selectedId: '',
        password: '',
      }
    })
  }

  getSubmitForm() {
    switch (this.state.modalType) {
      case 1:
        return this.submitAdd()
      case 2:
        return this.submitEdit()
      case 3:
        return this.submitDelete()
      case 4:
        return this.onSubmitSync()
      default:
        return this.submitAdd()
    }
  }

  get data() {
    return {
      name: this.state.name,
      username: this.state.username,
      password: this.state.password,
    }
  }

  submitAdd() {
    this.addAdmin.data = this.data
    this.props.dispatch(this.addAdmin.action()).then(() => {
      this._getSetting()
      this.modalToggle()
      KunjungToast({ title: 'Add Admin Success!' })
    })
  }

  submitDelete() {
    this.deleteAdmin.id = this.state.selectedId
    this.props.dispatch(this.deleteAdmin.action()).then(() => {
      this._getSetting()
      this.modalToggle()
      KunjungToast({ title: 'Admin Deleted!' })
    })
  }

  submitEdit() {
    this.editAdmin.id = this.state.selectedId
    this.editAdmin.data = this.data
    this.props.dispatch(this.editAdmin.action()).then(() => {
      this._getSetting()
      this.modalToggle()
      KunjungToast({ title: 'Edit Admin Success!' })
    })
  }

  getModalType() {
    switch (this.state.modalType) {
      case 1:
        return this.getModalAdd()
      case 2:
        return this.getModalAdd()
      case 3:
        return this.getModalDelete()
      default:
        return this.getModalDelete()
    }
  }

  getModalAdd() {
    return (
      <Container>
        <Form onSubmit={this.getSubmitForm.bind(this)} id="kunjung">
          <FormGroup>
            <Label for="name">Name :</Label>
            <KunjungInput
              type="text"
              id="name"
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="username">Username :</Label>
            <KunjungInput
              type="text"
              id="username"
              onChange={(e) => this.setState({ username: e.target.value })}
              value={this.state.username}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password :</Label>
            <KunjungInput
              type="password"
              id="password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </FormGroup>
        </Form>
      </Container>
    )
  }

  getModalDelete() {
    return <DeleteBody title="Apakah Anda Yakin ingin menghapus device ini ?" />
  }

  onModalDeleteClick(id) {
    console.log(id)
    this.setState(
      {
        modalType: 3,
      },
      () => {
        this.modalToggle()
        this.setState({ selectedId: id })
      }
    )
  }

  onClickAdd() {
    this.setState({ modalType: 1 }, () => {
      this.modalToggle()
    })
  }

  _handleSearchKeyDown(e) {
    if (e.key === 'Enter') {
      this.getSetting.params = this.params
      this._getSetting()
    }
  }

  componentDidMount() {
    this._getSetting()
  }
  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex">
            <h3 className="mr-auto mb-0">Setting</h3>
            {/* <form onSubmit={(e) => e.preventDefault()}>
              <KunjungInput
                className="kunjung-search"
                bsSize="sm"
                placeholder="Search here..."
                onKeyDown={(e) => this._handleSearchKeyDown(e)}
                onChange={(e) => this.setState({ searchValue: e.target.value })}
              />
            </form>
            <KunjungButton
              className="ml-2"
              size="sm"
              onClick={this.onClickAdd.bind(this)}
            >
              Add Admin
            </KunjungButton> */}
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Value</th>
                <th scope="col">Unit of Measure (UOM)</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.setting.data.map((setting, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{setting.name}</td>
                    <td>{setting.value}</td>
                    <td>{setting.uom}</td>
                    <td>
                      <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        color=""
                        onClick={() => this.onModalEditClick(setting)}
                      >
                        <i className="far fa-edit kunjung-text-primary"></i>
                      </Button>
                      <Button
                        className="btn-icon-only text-light"
                        size="sm"
                        color=""
                        onClick={() => this.onModalDeleteClick(setting.id)}
                      >
                        <i className="far fa-trash-alt kunjung-text-primary"></i>
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <CardFooter className="py-4"></CardFooter>
        </Card>
        <KunjungModal
          className="modal-dialog-centered"
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle.bind(this)}
          title={<h2>{this.getModalTitle()}</h2>}
          componentFooter={
            <Container>
              <Row>
                <Col>
                  <KunjungButton
                    outline
                    onClick={this.modalToggle.bind(this)}
                    block
                  >
                    Batal
                  </KunjungButton>
                </Col>
                <Col>
                  <KunjungButton
                    type="submit"
                    block
                    form="kunjung"
                    onClick={this.getSubmitForm.bind(this)}
                  >
                    {this.props.admin.isLoading ? (
                      <i class="fas fa-circle-notch fa-spin"></i>
                    ) : (
                      'Submit'
                    )}
                  </KunjungButton>
                </Col>
              </Row>
            </Container>
          }
        >
          {this.getModalType()}
        </KunjungModal>
      </KunjungAdminLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    setting: state.setting,
  }
}
export default connect(mapStateToProps)(Admin)
