import Action from '../Action'
import apiAdapter from '../../helpers/apiAdapter'
import { device } from '../../actionTypes'

class DeleteDevice extends Action {
  constructor() {
    super()
    this.type = device.DELETE_DEVICE
    this.url = '/device'
    this.id = null
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(DeleteDevice).delete(this.uri, this.config),
    }
  }
}

export default DeleteDevice
