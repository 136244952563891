import React from 'react'
import { Container } from 'reactstrap'

function SyncModal(props) {
  return (
    <Container>
      <div className="d-flex justify-content-center mb-3">
        <img
          src={require('../../assets/img/vector/19198998 1.png').default}
          alt=""
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <h3>Apa ingin synchronize device ini ?</h3>
      </div>
    </Container>
  )
}

export default SyncModal
