import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap'
import { getStatusPerson } from '../../helper'
import GetNotif from '../../redux/actions/person/GetNotif'
import AdminNavbar from './AdminNavbar'

class PersahabatanNavbar extends Component {
  constructor() {
    super()
    this.getNotif = new GetNotif()
  }
  _getNotif() {
    this.props.dispatch(this.getNotif.action())
  }

  componentDidMount() {
    this._getNotif()
  }
  render() {
    return (
      <AdminNavbar {...this.props} brandText={this.props.brandText}>
        <UncontrolledDropdown>
          <DropdownToggle className="pr-0r" nav>
            <span className="badge badge-pill badge-danger kunjung-badge-notif">
              {this.props.notif.length}
            </span>
            <i className="ni ni-bell-55" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {this.props.notif.map((notif) => (
              <DropdownItem tag={Link} to="/admin/employees">
                <span>
                  {notif.total} Employee {getStatusPerson(notif.status)}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </AdminNavbar>
    )
  }
}

function mapStateTopProps(state) {
  return {
    notif: state.person.notif,
  }
}
export default connect(mapStateTopProps)(PersahabatanNavbar)
