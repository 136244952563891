import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  FormGroup,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  Label,
} from 'reactstrap'

import KunjungAdminLayout from '../layout/KunjungAdminLayout'

import { connect } from 'react-redux'
import KunjungInput from '../../components/Form/Input'
import KunjungButton from '../../components/Button/Button'
import KunjungMultiDatePicker from '../../components/Form/MultiDatePicker'
import moment from 'moment'
import AbsentExport from '../../redux/actions/report/AbsentExport'

var fileDownload = require('js-file-download')

class Report extends Component {
  constructor() {
    super()
    this.exportAbsent = new AbsentExport()

    this.state = {
      value: [],
    }
  }

  get params() {
    const date = {}

    if (this.state.value) {
      if (this.state.value[0]) {
        date.startDate = moment(new Date(this.state.value[0])).format(
          'YYYY-MM-DD'
        )
      }

      if (this.state.value[1]) {
        date.endDate = moment(new Date(this.state.value[1])).format(
          'YYYY-MM-DD'
        )
      }
    }

    return {
      ...date,
    }
  }

  onChangePicker(e) {
    this.setState({ value: e })
  }

  _exportAbsent(e) {
    e.preventDefault()
    this.exportAbsent.params = this.params
    this.props.dispatch(this.exportAbsent.action()).then((res) => {
      fileDownload(res.value.data, 'Absent.xlsx')
    })
  }

  componentDidMount() {}
  render() {
    return (
      <KunjungAdminLayout>
        <Card className="shadow">
          <CardHeader className="d-flex">
            <h3 className="mr-auto mb-0">Report</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <Container>
                  <Form onSubmit={this._exportAbsent.bind(this)} id="kunjung">
                    <FormGroup>
                      <Label for="module">Module :</Label>
                      <select
                        className="kunjung-form-input form-control"
                        name="module"
                        id="module"
                      >
                        <option value="1">Absent</option>
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <Label for="date">Date :</Label>
                      <KunjungMultiDatePicker
                        className="kunjung-text-primary"
                        inputclassName="kunjung-search"
                        placeholder="Start Date ~ End Date"
                        format="DD MMM YYYY"
                        range
                        md
                        onChange={(e) => this.onChangePicker(e)}
                        value={this.state.value}
                      />
                    </FormGroup>
                    <KunjungButton
                      type="submit"
                      block
                      onClick={(e) => this._exportAbsent(e)}
                    >
                      Submit
                    </KunjungButton>
                    {/* <KunjungButton
                      type="button"
                      block
                      outline
                      // onClick={this.getSubmitForm.bind(this)}
                    >
                      Reset
                    </KunjungButton> */}
                  </Form>
                </Container>
              </Col>
              {/* <Col
                md={6}
                className="d-flex justify-content-center align-items-center flex-column"
              >
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{
                    fontSize: '55px',
                  }}
                ></i>
                <h3 className="mt-3">No Item</h3>
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </KunjungAdminLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
  }
}
export default connect(mapStateToProps)(Report)
