import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import RegistrationFactory from './factory/RegistrationFactory'

import AdminLayout from './layouts/Admin'
import Login from './views/kunjung/Login'
// import Registration from './views/persahabatan/Registration'

const { REACT_APP_CLIENT_NAME } = process.env

const registrationFactory = new RegistrationFactory()
const Registration = registrationFactory.create(REACT_APP_CLIENT_NAME)

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/registration"
            render={(props) => <Registration {...props} />}
          />
          {this.props.auth.token ? (
            <>
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Redirect from="/login" to="/admin/index" />
            </>
          ) : (
            <>
              <Route path="/login" render={(props) => <Login {...props} />} />
              <Redirect from="/admin" to="/login" />
            </>
          )}
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
  }
}
export default connect(mapStateToProps)(App)
