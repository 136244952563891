import { report } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'
import Action from '../Action'

class AbsentExport extends Action {
  constructor() {
    super()
    this.type = report.EXPORT_ABSENT
    this.url = '/attendance/export'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(AbsentExport, { responseType: 'blob' }).get(
        this.url,
        this.config
      ),
    }
  }
}

export default AbsentExport
