import Action from '../Action'
import { dashboard } from '../../actionTypes'
import apiAdapter from '../../helpers/apiAdapter'

class GetAttendace extends Action {
  constructor() {
    super()
    this.type = dashboard.CARD
    this.url = '/dashboard/card'
  }

  action() {
    return {
      type: this.type,
      payload: apiAdapter(GetAttendace).get(this.url, this.config),
    }
  }
}

export default GetAttendace
